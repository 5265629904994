import React from "react"
import moment from 'moment'

export default ({
    background,
    category,
    title,
    excerpt,
    minutesToRead,
    date
}) => {

    return (
        <div className="blog-single-hero">
            <div className="column">
                <div className="text">
                    <span>
                        { moment(date).format('DD.MM.YY') }
                    </span>
                    <h1 dangerouslySetInnerHTML={{ __html: title }} />
                </div>
                <img src={background}/>
            </div>
        </div>
    )
}