import React from "react"
import Link from 'components/link'
import BackToAll from '../images/backtoall.jpg'

import Button from 'components/button'

export default ({ 
    data,
    post
}) => {
    const {
        edges: posts
    } = data

    let counter = false

    const _nextPost = posts.find(({ node }) => {
        if (counter) {
            return true
        }
        
        if (node.slug === post.slug) {
            counter = true
        } else {
            counter = false
        }

        return
    })

    return (     
        <section className="TwoColumn">
            <ul>
                <li className="lighterGrey">
                    <Link to="/stories/"><i className={`fe fe-arrow-left`} /> Back to all</Link>
                    <div className="content">
                        <Link to="/stories/">
                            <div className="content-img" style={{backgroundImage: "url("+BackToAll+")"}} />
                        </Link>
                    </div>
                </li>
                <li className="lightGrey">
                    { _nextPost &&
                        <>
                            <Link 
                                to={ _nextPost.node.link }
                            >
                                Next Story <i className={`fe fe-arrow-right`} />
                            </Link>
                            { _nextPost.node.featuredImage &&
                                <div className="content">
                                    <Link to={ _nextPost.node.link }>
                                        <div className="content-img" style={{backgroundImage: "url("+_nextPost.node.featuredImage.mediaItemUrl+")"}} />
                                        <div className="hover">
                                            <div className="copy" dangerouslySetInnerHTML={{ __html: _nextPost.node.title }} />
                                            <div className="button-container">
                                                <Button 
                                                    onClick={() => {}}
                                                    label={'Read post'}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </div> 
                            }
                        </>
                    }
                </li>
            </ul>
        </section>
    )
}