import React from "react"
import Link from 'components/link'

export default ({ 
    label, 
    url, 
    onClick, 
    type = 'link',
    inverted,
    icon,
    iconAlign = 'right',
    disabled,
}) => {
    const buttonLabel = label || 'Read more'

    let className = 'button'

    if(inverted){
        className += ' inverted'
    }

    if(iconAlign === 'left'){
        className += ' icon-left'
    }

    const buttonContent = (
        <>
            <span>
                { buttonLabel }
                { icon ?
                    <i className={`fe fe-${ icon }`} />
                : null}
            </span>
        </>
    )

    if(onClick && !url){
        return (
            <button
                className={className}
                onClick={onClick}
                disabled={!!disabled}
            >
                { buttonContent }
            </button>
        )
    }else if (type === 'button' || url.startsWith('https') || url.startsWith('http') || url.startsWith('mailto:') || url.startsWith('tel:')) {
        return (
            <a 
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClick} 
                className={className}
            >
                { buttonContent }
            </a>
        )
    } else {
        return (
            <Link 
                to={url} 
                className={className}
            >
                { buttonContent }
            </Link>
        )
    }
}
